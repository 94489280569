import { Injectable } from '@angular/core';
import { Language } from 'src/app/shared/enums/utils/languages.enum';
import { Observable } from 'rxjs';
import { CallableNames, DbService } from '../db.service';
import { IPdfDocumentDefinition } from '../../models/certificate/certificate.model';

export enum FeAppType {
  MAIN = 'MAIN',
  WC = 'WC',
}

interface ICallablesUtilsLogFeErrorData {
  error: string;
  currUrl: string;
  deviceInfo?: any;
  severity?: any;
}

interface ICallablesPdfGenerateData {
  docDefinitions: IPdfDocumentDefinition[];
}

@Injectable({
  providedIn: 'root'
})
export class BeUtilsService {

  constructor(
    private dbService: DbService,
  ) { }

  public generatePdf(data: ICallablesPdfGenerateData): Observable<string[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPdfGenerate, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public logFeError(data: ICallablesUtilsLogFeErrorData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUtilsLogFeError, data: { ...data, appType: FeAppType.MAIN } });
    return this.dbService.handleObs(obs, { allSilent: true });
  }

  public getLanguageByIp(): Observable<Language | null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUtilsLanguageGet });
    return this.dbService.handleObs(obs, { allSilent: true });
  }
}
